import * as React from "react"
import { gql } from "@apollo/client"
import { Button, ButtonGroup, Heading, Stack, useDisclosure } from "@chakra-ui/react"
import dayjs from "dayjs"

import type { Quarter, SignableFormTaskFragment } from "lib/graphql"
import { useGetUserTasksQuery } from "lib/graphql"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { CreateExpenseForm } from "./CreateExpenseForm"
import { CreateInvoiceForm } from "./CreateInvoiceForm"
import { Modal } from "./Modal"
import { NoData } from "./NoData"
import { TasksList } from "./TasksList"
import { gatherTasks } from "lib/helpers/gatherTasks"

const _ = gql`
  fragment SignableFormTask on SignableForm {
    id
    templateName
  }
  fragment ReportTask on Report {
    id
    year
    quarter
    type
    file
  }
  fragment IncomeDataTask on IncomeData {
    id
    year
  }
  fragment UserTaskEntity on Entity {
    id
    name
    type
    hasUpcomingVatDeadline
    pendingReports(userId: $userId) {
      ...ReportTask
    }
    pendingIncomeData {
      ...IncomeDataTask
    }
    pendingSignableForms {
      ...SignableFormTask
    }
  }

  fragment UserTasks on User {
    id
    pendingPersonalSignableForms {
      ...SignableFormTask
    }
    entities {
      ...UserTaskEntity
    }
  }

  query GetUserTasks($userId: String!) {
    me {
      ...UserTasks
    }
  }
`
export type TaskUploadType = "expense" | "invoice"

interface Props {
  userId: string
}

export function UserTasks({ userId }: Props) {
  const bt = useBetterTranslation()

  const { data } = useGetUserTasksQuery({
    variables: { userId: userId },
    skip: !!!userId,
  })
  const entities = data?.me?.entities

  const entitiesWithTasks = entities?.filter(
    (entity) =>
      entity.hasUpcomingVatDeadline ||
      entity.pendingIncomeData.length > 0 ||
      entity.pendingReports.length > 0 ||
      entity.pendingSignableForms.length > 0,
  )

  const pendingPersonalSignableForms: SignableFormTaskFragment[] = React.useMemo(
    () => data?.me?.pendingPersonalSignableForms || [],
    [data?.me?.pendingPersonalSignableForms],
  )

  const modalProps = useDisclosure()
  const [selectedUpload, setSelectedUpload] = React.useState<TaskUploadType | null>(null)
  const [selectedEntity, setSelectedEntity] = React.useState<{ name: string; id: string } | null>(null)

  const handleModalClose = () => {
    modalProps.onClose()
    setSelectedUpload(null)
  }

  const defaultQuarter = `Q${dayjs()
    .startOf("Q")
    .subtract(1, "days")
    .startOf("Q")
    .quarter()
    .toString()}` as Quarter
  const defaultYear: string = dayjs().startOf("Q").subtract(1, "days").startOf("Q").year().toString()

  const allTasks = React.useMemo(() => {
    return gatherTasks(entitiesWithTasks, pendingPersonalSignableForms)
  }, [entitiesWithTasks, pendingPersonalSignableForms])

  return (
    <Stack w="100%">
      <Stack spacing={4} maxH="400px" overflowY="auto">
        <Heading as="h2" color="pink.500" size="md">
          {bt({
            en: "We need your help with the following…",
            nl: "We hebben je hulp nodig met het volgende...",
          })}
        </Heading>
        {allTasks.length > 0 ? (
          <TasksList tasks={allTasks} setSelectedEntity={setSelectedEntity} onOpen={modalProps.onOpen} />
        ) : (
          <NoData>{bt({ en: "No tasks", nl: "Geen taken" })}</NoData>
        )}
      </Stack>
      <Modal
        {...modalProps}
        onClose={handleModalClose}
        title={
          selectedUpload === "expense"
            ? bt({ en: "Upload expense", nl: "Uitgave uploaden" })
            : selectedUpload === "invoice"
            ? bt({ en: "Upload invoice", nl: "Omzetfactuur uploaden" })
            : bt({ en: "Upload", nl: "Uploaden" })
        }
        size="2xl"
      >
        {selectedUpload === "expense" ? (
          <>
            <CreateExpenseForm
              onClose={handleModalClose}
              selectedEntity={selectedEntity}
              selectedDate={{ year: defaultYear, quarter: defaultQuarter }}
            />
          </>
        ) : selectedUpload === "invoice" ? (
          <>
            <CreateInvoiceForm
              onClose={handleModalClose}
              selectedEntity={selectedEntity}
              selectedDate={{ year: defaultYear, quarter: defaultQuarter }}
            />
          </>
        ) : (
          <>
            <ButtonGroup>
              <Button onClick={() => setSelectedUpload("expense")}>
                {bt({ en: "Expense", nl: "Uitgave" })}
              </Button>
              <Button onClick={() => setSelectedUpload("invoice")}>
                {bt({ en: "Invoice", nl: "Omzetfactuur" })}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Modal>
    </Stack>
  )
}
