import * as React from "react"
import { gql } from "@apollo/client"
import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react"

import type { LatestReportItemFragment } from "../lib/graphql"
import { REPORT_TYPES } from "../lib/static/reportType"
import { LinkButton } from "./LinkButton"
import { Status } from "./Status"

const _ = gql`
  fragment LatestReportItem on Report {
    id
    createdAt
    quarter
    year
    type
    status
    file
    entity {
      id
      name
    }
  }
`

interface Props {
  report: LatestReportItemFragment
}

export function LatestReportItem({ report }: Props) {
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.100")
  const quarter = report.quarter?.toString().split("Q")[1]
  return (
    <LinkButton
      href={`/entities/${report.entity?.id}/${report.year}/${quarter ? quarter + "/" : ""}report/${
        report.id
      }`}
      backgroundColor={bg}
      py={8}
    >
      <Stack w="100%" spacing={1}>
        <Text fontWeight="bold">
          {REPORT_TYPES[report.type]} · {report.entity.name} · {report.year}
          {report.quarter ? ` ${report.quarter}` : ""}
        </Text>
        <Flex>
          <Status fontWeight="bold" mr={4} py={1} px={2} status={report.status} />
        </Flex>
      </Stack>
    </LinkButton>
  )
}
